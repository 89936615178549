class UserService {
    rolesList = [
        {
          key: "ROLE_SUPER_ADMIN",
          label: "A+"
        },
        {
          key: "ROLE_ADMIN",
          label: "A"
        },
        {
          key: "ROLE_SUPER_SALES",
          label: "B"
        },
        {
          key: "ROLE_SALES",
          label: "Commercial"
        }
    ]

    smallRolesList = [
        {
          key: "ROLE_ADMIN",
          label: "A"
        },
        {
          key: "ROLE_SUPER_SALES",
          label: "B"
        },
        {
          key: "ROLE_SALES",
          label: "Commercial"
        }
    ]

    getRole(roles) {
        var roleToShow = '';
        this.rolesList.map((role) => {
            if (Array.isArray(roles) && roles.includes(role.key)) {
                roleToShow = role.label;
            }
        });
        return roleToShow;
    }

    getRoleKey(roles) {
        var roleToShow = '';
        this.rolesList.map((role) => {
            if (Array.isArray(roles) && roles.includes(role.key)) {
                roleToShow = role.key;
            }
        });
        return roleToShow;
    }
}

export default new UserService();