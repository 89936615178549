<template>
<Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-12">
            <div class="row mt-3 flex-xs-column-reverse">
                <div class="col-sm-12 col-md-6 mt-2">
                    <div id="tickets-table_filter" class="dataTables_filter">
                        <label class="d-inline-flex align-items-center fw-normal">
                            Recherche
                            <b-form-input v-model="filter" type="search" class="form-control form-control-sm ms-2"></b-form-input>
                        </label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="text-end">
                        <b-button variant="primary" v-b-modal.modal-add>
                            Créer un utilisateur
                            <i class="uil uil-plus ms-2"></i>
                        </b-button>
                        <UserForm :type="'add'" @users-updated="onUsersUpdated" :show-super-admin="showSuperAdmin" />
                    </div>
                </div>
            </div>
            <div class="table-responsive mb-0">
                <b-table 
                    table-class="table table-centered datatable table-card-list"
                    thead-tr-class="bg-transparent"
                    :items="users"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                    <template v-slot:cell(lastname)="data">
                        <div class="avatar-xs d-inline-block me-2">
                            <span class="avatar-title rounded-circle">{{ data.item.firstname.charAt(0) }}{{ data.item.lastname.charAt(0) }}</span>
                        </div>

                        {{ data.item.firstname }} {{ data.item.lastname }}
                    </template>
                    <template v-slot:cell(roles)="data">
                        {{ UserService.getRole(data.item.roles) }}
                    </template>
                    <template v-slot:cell(enabled)="data">
                        <div class="custom-control custom-checkbox">
                            <div class="form-check">
                                <input @click.prevent v-model="data.item.enabled" type="checkbox" class="form-check-input" readonly content="Accès autorisé" v-tippy>
                            </div>
                        </div>
                    </template>
                    <template v-slot:cell(action)="data">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <button v-b-modal.modal-update class="btn px-2 text-primary" content="Modifier" v-tippy @click="onUpdateUser(data.item)" v-if="showSuperAdmin || UserService.getRole(data.item.roles) != 'A+'">
                                    <i class="uil uil-pen font-size-18"></i>
                                </button>
                            </li>
                        </ul>
                    </template>
                </b-table>
                <div class="text-center" v-if="loading">
                    <b-spinner class="align-middle" variant="primary"></b-spinner>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center fw-normal">
                            <span class="pe-2">Afficher</span>
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                            <span class="ps-2">entrées</span>
                        </label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <UserForm :type="'update'" @users-updated="onUsersUpdated" :selected-user="selectedUser" :show-super-admin="showSuperAdmin" />
</Layout>
</template>

<script>
    import Layout from "../layouts/main";
    import PageHeader from "@/components/page-header";
    import UserForm from "@/forms/user-form";
    import appConfig from "@/app.config";
    import api from '../../api/api';
    import Utilities from "../../services/utilities";
    import UserService from "../../services/user.service";

    /**
     * User component
     */
    export default {
        components: { Layout, PageHeader, UserForm },
        page: {
            title: "Utilisateurs",
            meta: [
            {
                name: "description",
                content: appConfig.description,
            },
            ],
        },
        props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
        data() {
            return {
                UserService,
                title: "Utilisateurs",
                items: [{
                        text: "Accueil",
                        to: "/"
                    },
                    {
                        text: "Utilisateurs",
                        active: true,
                    },
                ],
                users: [],
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "lastname",
                sortDesc: false,
                loading: true,
                fields: [
                    {
                        key: "lastname",
                        label: "Nom",
                        sortable: true,
                    },
                    {
                        key: "roles",
                        label: "Rôles"
                    },
                    {
                        key: "enabled",
                        label: "Accès",
                        sortable: true,
                    },
                    "action",
                ],
                selectedUser: {}
            };
        },
        computed: {
            rows() {
                return this.users.length;
            },
        },
        created() {
            if (!this.$props.showAdmin) {
                this.$router.push('/');
            }
            this.totalRows = this.items.length;
            this.loadUsers();
        },
        methods: {
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            closeModal(type) {
                this.$bvModal.hide('modal-' + type);
            },
            onUsersUpdated(payload) {
                this.closeModal(payload.type);
                this.loadUsers();
            },
            onUpdateUser(user) {
                this.selectedUser = user;
            },
            loadUsers() {
                api
                    .get('/users')
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                this.users = Object.values(response.data);
                                this.loading = false;
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            }
        },
        middleware: "authentication",
    };
</script>

<style lang="scss" scoped>
    @media all and (max-width: 576px) {
        .dataTables_filter::v-deep {
            label, input {
                width: 100%;
            }

            label {
                margin-top: 30px;
            }
        }

        .flex-xs-column-reverse {
            flex-direction: column-reverse;
        }
    }

    ::v-deep {
      th, td {
        white-space: nowrap;
      }
    }

    ::v-deep .table.b-table > thead > tr {
        & >[aria-sort] {
            cursor: pointer;
            background-image: none;
            background-repeat: no-repeat;
            background-size: 0.65em 1em;

            &:not(.b-table-sort-icon-left) {
                background-position: right 0.375rem center;
                padding-right: calc(0.75rem + 0.65em);
            }
        }

        & > [aria-sort=descending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=ascending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=none] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }
    }
</style>