<template>
    <b-modal :id="'modal-' + type" :title="modalTitle" hide-footer size="lg" centered>
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="firstname">Prénom</label>
                            <input
                                v-model="user.firstname"
                                type="text"
                                class="form-control"
                                id="firstname"
                                placeholder="Entrer un prénom"
                                name="firstname"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="lastname">Nom de famille</label>
                            <input
                                v-model="user.lastname"
                                type="text"
                                class="form-control"
                                id="lastname"
                                placeholder="Entrer un nom"
                                name="lastname"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group
                          label="Email"
                          label-for="email"
                          class="mb-3"
                        >
                          <b-form-input
                            id="email"
                            type="email"
                            v-model="user.email"
                          ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="unit">Rôle</label>
                            <select 
                                v-model="roleSelected"
                                class="form-select"
                            >
                                <option v-for="role in rolesList" :value="role.key" :key="role.key">{{ role.label }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6 col-md-3">
                        <div class="custom-control custom-checkbox">
                            <div class="form-check">
                                <input
                                    v-model="user.enabled"
                                    type="checkbox"
                                    class="form-check-input"
                                    id="enabled"
                                >
                                <label class="form-check-label" for="enabled">Accès autorisé</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="custom-control custom-checkbox">
                            <div class="form-check">
                                <input
                                    v-model="user.binomialAllowed"
                                    type="checkbox"
                                    class="form-check-input"
                                    id="binomialAllowed"
                                >
                                <label class="form-check-label" for="binomialAllowed">Sur le terrain</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-end">
                            <button @click.prevent="createUser()" class="btn btn-primary" v-if="type == 'add'">
                                Ajouter l'utilisateur
                                <i class="uil uil-plus ms-2"></i>
                            </button>
                            <button @click.prevent="updateUser()" class="btn btn-primary" v-else-if="type == 'update'">
                                Modifier l'utilisateur
                                <i class="uil uil-pen ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
    import Swal from "sweetalert2";
    import api from '../api/api';
    import User from '../models/user';
    import Utilities from "../services/utilities";
    import UserService from "../services/user.service";

    export default {
        name: 'UserForm',
        data() {
            return {
                UserService,
                submitted: false,
                errors: [],
                user: new User('', '', [], '', true, false),
                modalTitle: '',
                roleSelected: '',
                rolesList: []
            }
        },
        props: ['type', 'selectedUser', 'showSuperAdmin'],
        mounted() {
            if (!this.showSuperAdmin) {
                this.rolesList = UserService.smallRolesList;
            } else {
                this.rolesList = UserService.rolesList;
            }

            if (this.type == 'update') {
                this.user = {...this.selectedUser};
                if (this.user.roles) {
                    this.roleSelected = UserService.getRoleKey(this.user.roles);
                } 
                this.modalTitle = 'Modifier un utilisateur';
            } else {
                this.modalTitle = 'Ajouter un utilisateur';
            }
        },
        watch: {
            selectedUser(data) {
                this.user = data;
                if (data.roles) {
                    this.roleSelected = UserService.getRoleKey(data.roles);
                } 
            }
        },
        methods: {
            userValid() {
                this.errors = [];
    
                if (!this.user.firstname) {
                    this.errors.push('Le prénom est obligatoire');
                }
                if (!this.user.lastname) {
                    this.errors.push('Le nom est obligatoire');
                }
    
                if (!this.user.email) {
                    this.errors.push('L\'email est obligatoire');
                }
    
                if (this.errors.length == 0) {
                    return true;
                } else {
                    Swal.fire("Erreur", this.errors[0], "error");
                    return false;
                }
            },
            createUser: function () {
                this.user.roles = [];
                this.user.roles.push(this.roleSelected);

                if (this.userValid()) {
                    api
                        .post('/users', this.user)
                        .then(
                            (response) => {
                                if (response && response.status == 201) {
                                    Utilities.successmsg("L'utilisateur a bien été ajouté !");
                                    this.user = new User('', '', [], '', true),
                                    this.$emit('users-updated', {type: this.type});
                                }
                            },
                            (err) => {
                                if (err.response.status == 422) {
                                    let message = err.response.data.detail.split(': ');
                                    Utilities.alertmsg((message.length > 1) ? message[1] : 'Une erreur s\'est produite');
                                } else {
                                    Utilities.errormsg();
                                }
                            }
                        )
                }
            },
            updateUser: function () {
                this.user.roles = [];
                this.user.roles.push(this.roleSelected);

                if (this.userValid()) {
                    let userToSend = {...this.user};
                    delete userToSend.id;

                    api
                        .patch('/users/' + this.user.id, userToSend, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json'
                            }
                        })
                        .then(
                            (response) => {
                                if (response && response.status == 200) {
                                    Utilities.successmsg("L'utilisateur a bien été modifié !");
                                    this.user = new User('', '', [], '', true),
                                    this.$emit('users-updated', { type: this.type });
                                }
                            },
                            (error) => {
                                if (error.response.status == 403 && error.response.data.detail == 'Access Denied.') {
                                    Utilities.alertmsg("Vous n'avez pas les droits pour effectuer cette opération !");
                                } else {
                                    Utilities.errormsg();
                                }
                            }
                        )
                }
            },
        }
    }
</script>